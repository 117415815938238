import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { UserService } from '../../@domain/user/application/user.service';

export const adminGuardFn: CanActivateFn = () => {
    const userService = inject(UserService);
    const router = inject(Router);

    return !!userService.userProfile()?.user.is_staff || router.createUrlTree([ '/login' ]);
}
