import { Routes } from '@angular/router';
import { ADMIN_ROUTES, AdminRoutes } from './admin.routes';
import { APP_ROUTES } from './app.routes';
import { adminGuardFn } from './guards/admin.guard';
import { authGuardFn } from './guards/auth.guard';

export enum GenericRoute {
    ErrorPage = 'error-page',
    Unauthorized = 'unauthorized',
}

export const ROUTES: Routes = [
    {
        path: GenericRoute.ErrorPage,
        loadComponent: () => import('../@page/app/not-found/not-found.page')
    },
    {
        path: GenericRoute.Unauthorized,
        loadComponent: () => import('../@page/app/unauthorized/unauthorized.page')
    },
    {
        path: AdminRoutes.Admin,
        canActivate: [ authGuardFn, adminGuardFn ],
        loadChildren: () => ADMIN_ROUTES
    },
    ...APP_ROUTES,
    {
        path: '**',
        redirectTo: GenericRoute.ErrorPage
    }
];
