export enum AdminRoutes {
    Admin = 'admin',
    Home = '',
    Videos = 'videos',
}

export const ADMIN_ROUTES = [
    {
        path: AdminRoutes.Home,
    },
    {
        path: AdminRoutes.Videos,
    }
]
