import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, inject, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { NgLetModule } from 'ng-let';
import { filter, map, Observable } from 'rxjs';
import { UserService } from 'src/app/@domain/user/application/user.service';
import { onFirstClick } from '../../@common/DOM/close-on-first-click.function';
import { ImageDirective } from '../../@common/DOM/image.directive';
import { AdminRoutes } from '../../routing/admin.routes';
import { AppRoutes } from '../../routing/app.routes';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: [ './header.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgLetModule,
        RouterLink,
        ImageDirective,
        FormsModule,
        FaIconComponent,
        NgIf,
        AsyncPipe,
    ],
})
export class HeaderComponent {
    private userService = inject(UserService);
    private router = inject(Router);

    userProfile = this.userService.userProfile;
    isAuthenticated = this.userService.isAuthenticated;
    isAdmin = this.userService.isAdmin;
    redBackground$: Observable<boolean>;
    isMenuOpen = signal(false);
    isSignedInMenuOpen = signal(false);
    query: string = '';
    faSearch = faSearch;
    AppRoutes = AppRoutes;
    AdminRoutes = AdminRoutes;

    constructor() {
        this.redBackground$ = this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map((event: any) => event.url === '/' || event.url === '/lecture-agreement')
            );

        effect(() => {
            if (this.isSignedInMenuOpen()) {
                onFirstClick(() => this.isSignedInMenuOpen.set(false))
            }
        })
    }

    public onLogout() {
        this.userService.logout();
    }

    public onSubmit(): void {
        if (this.query.length == 0) return;

        this.router.navigate([ AppRoutes.Search ], {
            queryParams: { query: this.query },
        });

        this.query = '';
    }

    public toggleProfileMenu(): void {
        this.isSignedInMenuOpen.set(!this.isSignedInMenuOpen());
    }

}
