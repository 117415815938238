<footer class="bg-dark">
  <div class="container">
    <div class="footer py-3">
      <div>
        <div class="grid grid-3 p-2">
          <div class="grid grid-2">
            <div>
              <h3 class="py-1">VIDEOLECTURES</h3>
              <ul>
                  <li><a [routerLink]="AppRoutes.Company">About</a></li>
              </ul>
            </div>

            <div>
              <h3 class="py-1">LEGAL</h3>
              <ul>
                <li>
                  <a routerLink="/lecture-agreement">Disclosure Statement</a>
                </li>
                <li>
                  <a routerLink="/privacy-terms-of-use"
                    >Privacy & Terms of Use</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <!-- <div class="grid grid-2">
            <div>
              <h3 class="py-1">SUPPORT</h3>
              <ul>
                <li><a routerLink="">Support</a></li>
              </ul>
            </div>
                   <div class="subscribe">
            <h3 class="py-1">SUBSCRIBE TO OUR NEWSLETTER</h3>
            <a
              >The latest news, articles, and resources, sent to your inbox
              weekly.</a
            >
            <form>
              <div for="email" class="border">
                <input
                  id="email"
                  name="email"
                  [(ngModel)]="email"
                  type="text"
                  class="form-field"
                  placeholder="Enter your email"
                  autocomplete="off"
                />
              </div>
              <button class="btn">Subscribe</button>
            </form>
          </div> -->
        </div>
        <!--      <div class="line"></div>

        <div class="d-flex p-2">
          <p class="rights-text">© 2020 Workflow, Inc. All rights reserved.</p>
          <div class="social">
            <a href="#"><fa-icon [icon]="faFacebook"></fa-icon></a>
            <a href="#"><fa-icon [icon]="faInstagram"></fa-icon></a>
            <a href="#"><fa-icon [icon]="faTwitter"></fa-icon></a>
            <a href="#"><fa-icon [icon]="faGithub"></fa-icon></a>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</footer>
