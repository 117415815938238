import { ErrorHandler, inject, Injectable } from '@angular/core';
import { Logger } from './logger';

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
    private logger = inject(Logger);

    public handleError(error: any) {
        this.logger.error(error.message || 'Unhandled Error', error);
    }

}
