import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { NavigationEnd, NavigationError, Router, RouterOutlet } from '@angular/router';
import { environment } from '../environments/environment';
import { ModalService } from './@domain/modal/modal.service';
import { FooterComponent } from './@ui/footer/footer.component';
import { HeaderComponent } from './@ui/header/header.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: [ './app.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        HeaderComponent,
        RouterOutlet,
        FooterComponent,
    ],
})
export class AppComponent implements OnInit {
    private modalService = inject(ModalService);
    private router = inject(Router);

    ngOnInit(): void {
        document.body.addEventListener('click', (e: Event) => {
            this.modalService.checkModalWindow(e);
        });

        console.debug('Version:', environment.version);

        this.router.events.subscribe(evt => {
            if (evt instanceof NavigationEnd) {
                this.modalService.close()
            }

            if (evt instanceof NavigationError && evt.error.message.includes('dynamically imported')) {
                window.location.assign(evt.url);
            }
        });
    }
}
