import { HttpErrorResponse, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { catchError, EMPTY, throwError } from 'rxjs';
import { LocalStorageService } from '../../../@domain/storage/application/local-storage.service';
import { ApiPath } from '../api-path.enum';

export function AuthInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn) {
    const localStorageService = inject(LocalStorageService);
    const toastr = inject(ToastrService);

    if (!req.withCredentials) {
        return next(req);
    }

    const token = localStorageService.getAuthToken();

    if (!token) {
        return next(req);
    }

    const headers = req.headers.set('Authorization', `Token ${ token }`);
    return next(req.clone({ headers })).pipe(
        catchError(error => {
            if (error instanceof HttpErrorResponse && !req.url.includes(ApiPath.auth) && error.status === 401) {
                toastr.error('Your session has expired. Please log in again.', undefined, { disableTimeOut: true });
                localStorageService.setAuthToken(null);
                return EMPTY;
            }

            return throwError(() => error);
        })
    );

}
